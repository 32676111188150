import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageTemplateDetails from '../components/PageTemplateDetails'
import favicon from '../pages/favicon.ico'


class PageTemplate extends React.Component {
  render() {
    const { title, subtitle, url } = this.props.data.site.siteMetadata
    const page = this.props.data.markdownRemark
    const { title: pageTitle, description: pageDescription } = page.frontmatter
    const description = pageDescription !== null ? pageDescription : subtitle
    console.log(page);
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${pageTitle} - ${title}`}</title>
            <meta name="description" content={description} />
            <link rel="icon" href={favicon} />
            <link rel="canonical" href={`${url}${page.fields.slug}${"/"}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${pageTitle} ${title}`} />
            <meta property="og:url" content={`${url}${page.fields.slug}${"/"}`} />
            <meta property="og:description" content={description} />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:site_name" content={title} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@smashou" />
            <meta name="twitter:title" content={`${pageTitle} ${title}`} />
            <meta name="twitter:description" content={description} />

          </Helmet>
          <PageTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        url
        menu {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          linkedin
          rss
          vk
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        date
        description
      }
    }
  }
`
